// @flow
import React, { useState } from 'react';
import { Form, InputGroup, FloatingLabel } from 'react-bootstrap';
import classNames from 'classnames';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';

/* Password Input */
const PasswordInput = ({
  name,
  placeholder,
  refCallback,
  errors,
  register,
  className,
  onChange = () => {}
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <InputGroup className="mb-0">
        <Form.Control
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder}
          name={name}
          id={name}
          as="input"
          ref={(r) => {
            if (refCallback) refCallback(r);
          }}
          className={className}
          isInvalid={errors && errors[name] ? true : false}
          {...(register ? register(name, { onChange: onChange }) : {})}
          autoComplete={name}
        />
        <div
          className={classNames('input-group-text', 'input-group-password', {
            'show-password': showPassword
          })}
          data-password={showPassword ? 'true' : 'false'}
        >
          <span
            className="password-eye"
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          />
        </div>
      </InputGroup>
    </>
  );
};

const FormInput = ({
  label,
  type,
  name,
  placeholder,
  register,
  errors,
  className,
  labelClassName,
  containerClass,
  refCallback,
  children,
  floatingLabel = false,
  radioOptions = [],
  ...otherProps
}) => {
  const comp =
    type === 'textarea' ? 'textarea' : type === 'select' ? 'select' : 'input';

  const renderInput = () => {
    switch (type) {
      case 'hidden':
        return (
          <input
            type={type}
            name={name}
            {...(register ? register(name) : {})}
            {...otherProps}
          />
        );

      case 'password':
        return (
          <Form.Group className={containerClass}>
            {label && (
              <>
                <Form.Label className={labelClassName}>{label}</Form.Label>
                {children}
              </>
            )}
            <PasswordInput
              name={name}
              placeholder={placeholder}
              refCallback={refCallback}
              errors={errors}
              register={register}
              className={className}
              {...otherProps}
            />
            {errors?.[name] && (
              <Form.Control.Feedback type="invalid" className="d-block">
                {errors[name].message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        );

      case 'select':
        return (
          <Form.Group className={containerClass}>
            {label && !floatingLabel && (
              <Form.Label className={labelClassName}>{label}</Form.Label>
            )}
            {!floatingLabel ? (
              <Form.Select
                name={name}
                id={name}
                ref={(r) => refCallback?.(r)}
                className={className}
                isInvalid={!!errors?.[name]}
                {...(register
                  ? register(name, { onChange: otherProps?.onChange })
                  : {})}
                {...otherProps}
              >
                {children}
              </Form.Select>
            ) : (
              <FloatingLabel controlId={name} label={label}>
                <Form.Select
                  name={name}
                  id={name}
                  ref={(r) => refCallback?.(r)}
                  className={className}
                  isInvalid={!!errors?.[name]}
                  {...(register
                    ? register(name, { onChange: otherProps?.onChange })
                    : {})}
                  {...otherProps}
                >
                  {children}
                </Form.Select>
              </FloatingLabel>
            )}
            {errors?.[name] && (
              <Form.Control.Feedback type="invalid">
                {errors[name].message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        );

      case 'checkbox':
        return (
          <>
            <Form.Group className={containerClass}>
              <Form.Check
                type={type}
                label={label}
                name={name}
                id={name}
                ref={(r) => {
                  if (refCallback) refCallback(r);
                }}
                className={className}
                isInvalid={errors && errors[name] ? true : false}
                {...(register ? register(name) : {})}
                {...otherProps}
              />

              {errors && errors[name] ? (
                <Form.Control.Feedback type="invalid">
                  {errors[name]['message']}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
          </>
        );

      case 'radio':
        if (radioOptions.length > 0) {
          return (
            <Form.Group className={`${containerClass} mb-2`}>
              {label && (
                <Form.Label className={`${labelClassName} d-block`}>
                  {label}
                </Form.Label>
              )}
              {radioOptions.map((option, index) => (
                <Form.Check
                  key={index}
                  type={type}
                  label={option.label}
                  name={name}
                  id={`${name}-${option.value}-${option.id}`}
                  inline={option.inline || false}
                  defaultChecked={option.defaultSelected || false}
                  ref={(r) => refCallback?.(r)}
                  className={className}
                  isInvalid={!!errors?.[name]}
                  value={option.value}
                  {...(register ? register(name) : {})}
                  {...otherProps}
                />
              ))}
              {errors?.[name] && (
                <Form.Control.Feedback type="invalid">
                  {errors[name].message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          );
        }
        break;

      case 'tel':
        return (
          <Form.Group className={containerClass}>
            {label && <Form.Label className={labelClassName}>{label}</Form.Label>}
            <PhoneInputWithCountry
              placeholder={placeholder}
              name={name}
              id={name}
              className={`form-control ${
                errors?.[name] ? 'is-invalid' : ''
              } phone-number-input ${className}`}
              defaultValue=""
              {...otherProps}
            />
            {errors?.[name] && (
              <Form.Control.Feedback type="invalid">
                {errors[name].message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        );

      default:
        return (
          <Form.Group className={containerClass}>
            {label && <Form.Label className={labelClassName}>{label}</Form.Label>}
            <Form.Control
              type={type}
              placeholder={placeholder}
              name={name}
              id={name}
              as={comp}
              ref={(r) => refCallback?.(r)}
              className={className}
              isInvalid={!!errors?.[name]}
              {...(register ? register(name) : {})}
              {...otherProps}
            >
              {children}
            </Form.Control>
            {errors?.[name] && (
              <Form.Control.Feedback type="invalid">
                {errors[name].message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        );
    }
  };

  return <>{renderInput()}</>;
};

export default FormInput;
