import React, { createContext, useMemo } from 'react';
import {
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute
} from 'amazon-cognito-identity-js';
import * as Sentry from '@sentry/react';
import Pool from '../core/utils/UserPool';
import { CognitoErrors } from '../core/config/Constants';

const AccountContext = createContext();

const Account = (props) => {
  const user = {};
  let cognitoUser;

  const getSession = () => {
    return new Promise((resolve, reject) => {
      cognitoUser = Pool.getCurrentUser();
      if (cognitoUser != null) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            reject(err);
            return;
          }
          user.session = session;
          cognitoUser.getUserAttributes((error, result) => {
            if (error) {
              console.log(err);
              reject(err);
              return;
            }
            user.attributes = result;
            resolve(result);
          });
        });
      } else {
        reject('No user');
      }
    });
  };

  const authenticate = (Username, Password) =>
    new Promise((resolve, reject) => {
      const cognitoUsername = Username.trim().toLowerCase().replace('@', '__');
      cognitoUser = new CognitoUser({
        Username: cognitoUsername,
        Pool
      });
      cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');

      const authDetails = new AuthenticationDetails({
        Username: cognitoUsername,
        Password
      });

      cognitoUser.authenticateUser(authDetails, {
        onSuccess: (data) => {
          Sentry.setUser({ email: Username });
          resolve(data);
        },
        onFailure: (err) => {
          reject(err);
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          resolve({
            userAttributes,
            requiredAttributes,
            newPasswordRequired: true
          });
        }
      });
    });

  const handleForgotPassword = (Username) =>
    new Promise((resolve, reject) => {
      const cognitoUsername = Username.trim().toLowerCase().replace('@', '__');
      cognitoUser = new CognitoUser({
        Username: cognitoUsername,
        Pool
      });

      cognitoUser.forgotPassword({
        onSuccess: (data) => {
          resolve(data);
        },
        onFailure: (err) => {
          reject(err);
        },
        undefined
      });
    });

  const resendConfirmationCode = (Username) =>
    new Promise((resolve, reject) => {
      const cognitoUsername = Username.trim().toLowerCase().replace('@', '__');
      cognitoUser = new CognitoUser({
        Username: cognitoUsername,
        Pool
      });

      cognitoUser.resendConfirmationCode((err, result) => {
        if (err) {
          console.log(err);
          reject(err);
        } else {
          resolve(result);
        }
      });
    });

  const handleConfirmPassword = (Username, verificationCode, newPassword) =>
    new Promise((resolve, reject) => {
      const cognitoUsername = Username.trim().toLowerCase();
      cognitoUser = new CognitoUser({
        Username: cognitoUsername,
        Pool
      });

      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: (data) => {
          resolve(data);
        },
        onFailure: (err) => {
          reject(err);
        }
      });
    });

  const logout = (forceReload = true) => {
    cognitoUser = Pool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
      if (forceReload) {
        window.location.reload(false);
      }
    }
  };

  const handleNewPassword = (newPassword, dataObj) => {
    const { requiredAttributes } = dataObj;
    return new Promise((resolve, reject) => {
      cognitoUser.completeNewPasswordChallenge(newPassword, requiredAttributes, {
        onSuccess: (data) => {
          resolve(data);
        },
        onFailure: (err) => {
          console.log(err);
          reject(err);
        }
      });
    });
  };

  const getUsername = () => {
    return cognitoUser.getUsername();
  };

  const handleSignUp = ({
    firstName,
    lastName,
    email,
    password,
    currentRank,
    preferredVessel,
    referralType,
    tandc,
    phoneNumber,
    countryCode,
    currency,
    salary,
    gender
  }) => {
    const attributeList = [];

    const dataEmail = {
      Name: 'email',
      Value: email.trim().toLowerCase()
    };

    const dataSurname = {
      Name: 'custom:surname',
      Value: lastName
    };

    const dataName = {
      Name: 'name',
      Value: firstName
    };

    const dataCurrentRank = {
      Name: 'custom:currentRank',
      Value: currentRank
    };

    const dataPreferredVessel = {
      Name: 'custom:preferredVessel',
      Value: preferredVessel
    };

    const dataReferralType = {
      Name: 'custom:referralType',
      Value: referralType
    };

    const dataTandC = {
      Name: 'custom:tandc',
      Value: String(tandc)
    };

    const dataPhoneNumber = {
      Name: 'custom:phoneNumber',
      Value: String(phoneNumber)
    };

    const dataCountryCode = {
      Name: 'custom:countryCode',
      Value: String(countryCode)
    };

    const dataCurrency = {
      Name: 'custom:salaryCurrency',
      Value: String(currency)
    };

    const dataSalary = {
      Name: 'custom:salaryExpectation',
      Value: String(salary)
    };

    const dataGender = {
      Name: 'custom:Gender',
      Value: String(gender).toUpperCase()
    };

    const attributeEmail = new CognitoUserAttribute(dataEmail);
    const attributeSurname = new CognitoUserAttribute(dataSurname);
    const attributeName = new CognitoUserAttribute(dataName);
    const attributeCurrentRank = new CognitoUserAttribute(dataCurrentRank);
    const attributePreferredVessel = new CognitoUserAttribute(dataPreferredVessel);
    const attributeReferralType = new CognitoUserAttribute(dataReferralType);
    const attributeTandC = new CognitoUserAttribute(dataTandC);
    const attributePhoneNumber = new CognitoUserAttribute(dataPhoneNumber);
    const attributeCountryCode = new CognitoUserAttribute(dataCountryCode);
    const attributeCurrency = new CognitoUserAttribute(dataCurrency);
    const attributeSalary = new CognitoUserAttribute(dataSalary);
    const attributeGender = new CognitoUserAttribute(dataGender);

    attributeList.push(attributeEmail);
    attributeList.push(attributeSurname);
    attributeList.push(attributeName);
    attributeList.push(attributeCurrentRank);
    attributeList.push(attributePreferredVessel);
    attributeList.push(attributeReferralType);
    attributeList.push(attributeTandC);
    attributeList.push(attributePhoneNumber);
    attributeList.push(attributeCountryCode);
    attributeList.push(attributeCurrency);
    attributeList.push(attributeSalary);
    attributeList.push(attributeGender);

    return new Promise((resolve, reject) => {
      Pool.signUp(
        email.trim().toLowerCase().replace('@', '__'),
        password,
        attributeList,
        null,
        (err, res) => {
          if (err) {
            reject(err);
          } else {
            const userObj = res.user;
            resolve(userObj);
          }
        }
      );
    });
  };

  const handleConfirmUser = ({ userName, confirmationCode }) => {
    return new Promise((resolve, reject) => {
      const cognitoUsername = userName.trim().toLowerCase();
      const cognitoConfirmUser = new CognitoUser({
        Username: cognitoUsername,
        Pool
      });
      cognitoConfirmUser.confirmRegistration(
        confirmationCode,
        true,
        (err, result) => {
          if (err) {
            console.log(err);
            reject(err);
          } else {
            resolve(result);
          }
        }
      );
    });
  };

  const handleChangePassword = ({ oldPassword, newPassword }) => {
    return new Promise((resolve, reject) => {
      cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
        if (err) {
          alert(err.message || JSON.stringify(err));
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  };

  const checkEmailAvailability = (Username) =>
    new Promise((resolve, reject) => {
      const cognitoUsername = Username.trim().toLowerCase().replace('@', '__');
      cognitoUser = new CognitoUser({
        Username: cognitoUsername,
        Pool
      });
      cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');

      const authDetails = new AuthenticationDetails({
        Username: cognitoUsername,
        Password: '000'
      });

      cognitoUser.authenticateUser(authDetails, {
        onSuccess: () => {
          resolve(false);
        },
        onFailure: (err) => {
          const code = err.code;

          if (code === CognitoErrors.USER_NOT_FOUND) {
            resolve(true);
            return;
          }

          reject(false);
        },
        newPasswordRequired: () => {
          resolve(false);
        }
      });
    });

  const providerValue = useMemo(
    () => ({
      authenticate,
      getSession,
      logout,
      handleNewPassword,
      handleForgotPassword,
      handleConfirmPassword,
      handleSignUp,
      handleConfirmUser,
      handleChangePassword,
      resendConfirmationCode,
      user,
      getUsername,
      checkEmailAvailability
    }),
    [user]
  );
  return (
    <div>
      <AccountContext.Provider value={providerValue}>
        {props.children}
      </AccountContext.Provider>
    </div>
  );
};

export { Account, AccountContext };
